import helpers from 'helpers/index'
import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  styled
} from '@mui/material'

type Type =
  | 'text'
  | 'email'
  | 'number'
  | 'integer'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency'

type TypedTextField = {
  className?: string
  placeholder?: string
  /** Initial value for the input */
  value?: string
  /** Additional hint text to display */
  helpText?: React.ReactNode
  /** Label for the input */
  label?: React.ReactNode
  /** Visually hide the label */
  labelHidden?: boolean
  /** Disable the input */
  disabled?: boolean
  /** Disable editing of the input */
  readOnly?: boolean
  /** Automatically focus the input */
  autoFocus?: boolean
  /** Allow for multiple lines of input */
  multiline?: boolean
  /** Error to display beneath the label */
  error?: Error | boolean | string
  valid?: boolean
  /** Determine type of input */
  type?: Type
  /** Name of the input */
  name?: string
  /** ID for the input */
  id?: string
  /** Enable automatic completion by the browser. Set to "off" when you do not want the browser to fill in info */
  autoComplete?: string
  /** Maximum character length for an input */
  maxLength?: number
  /** Minimum character length for an input */
  minLength?: number
  /** Maximum character length for an input */
  min?: number
  /** Minimum character length for an input */
  max?: number
  /** Indicate whether value should have spelling checked */
  spellCheck?: boolean
  /** Visual required indicator, adds an asterisk to label */
  requiredIndicator?: boolean
  rows?: number
  isShowMessage?: boolean
  required?: boolean
  iconStart?: React.ReactElement
  iconEnd?: React.ReactElement
  positionIcon?: 'start' | 'end'
  /** Callback fired when value is changed */
  onChange?(value: string, id: string): void
  /** Callback fired when input is focused */
  onFocus?: (event?: React.FocusEvent) => void
  onBlur?: (event?: React.FocusEvent) => void

  /** Migrate ... */
  runValidation?: (s: string) => void
  setError?: (s: any) => void
}

/**
 * TextField, support validate and simple to use
 * @param props: @see TypedTextField
 * @returns
 */
function TextField(props: TypedTextField) {
  let {
    id,
    type,
    label,
    labelHidden,
    helpText,
    placeholder,
    disabled,
    readOnly,
    className,
    maxLength,
    minLength,
    min,
    max,
    name,
    value,
    onChange,
    onFocus,
    requiredIndicator,
    spellCheck,
    autoFocus,
    error,
    autoComplete,
    // migrate
    runValidation,
    setError,
    onBlur,
    multiline,
    rows,
    iconEnd,
    required,
    isShowMessage = false
  } = props
  if (typeof id === 'undefined' || !id) id = helpers.slugify(Math.random())
  if (typeof type === 'undefined' || !type) type = 'text'

  const onBlurCallback = (event: any) => {
    if (typeof onBlur === 'function') {
      return onBlur.apply(this, event)
    }
    if (typeof runValidation === 'function') {
      return runValidation.call(this, event.target.value)
    }
  }

  const onFocusCallback = (event: any) => {
    // onFocus
    if (typeof onFocus === 'function') {
      return onFocus.apply(this, event)
    }
    if (typeof setError === 'function') {
      return setError.call(this, '')
    }
  }

  return (
    <TextFiledContainer variant="outlined" sx={{ m: 1 }}>
      {!labelHidden && label && (
        <InputLabel
          htmlFor={id}
          sx={{
            marginBottom: theme => theme.spacing(1),
            fontSize: theme => theme.spacing(2),
            display: 'flex',
            columnGap: theme => theme.spacing(0.5)
          }}
        >
          {label} {requiredIndicator && <Typography color="error.main">*</Typography>}
        </InputLabel>
      )}
      <OutlinedInput
        sx={{
          width: '100%',
          p: theme => theme.spacing(0, 2),
          borderRadius: theme => theme.spacing(1.5),
          background: theme => theme.palette.background.default,
          outline: 'none',
          textAlign: 'center',
          '& .Mui-focused': { border: 'none !important' },
          '& fieldset span, legend': { width: 'auto', fontSize: theme => theme.spacing(1.5) }
        }}
        multiline={multiline}
        rows={rows}
        label={label ?? ''}
        aria-describedby={id + '_describe'}
        onFocus={onFocusCallback}
        required={required ?? false}
        className={className ?? ''}
        type={type}
        id={id}
        disabled={disabled ?? false}
        readOnly={readOnly ?? false}
        name={name ?? ''}
        value={value ?? ''}
        // defaultValue={value ?? ''}
        spellCheck={spellCheck ?? false}
        inputProps={{
          min: min ?? 0,
          max: max ?? 99999999999,
          maxLength: maxLength ?? 99999999999,
          minLength: minLength ?? 0
        }}
        error={Boolean(error)}
        placeholder={placeholder ?? ''}
        autoFocus={autoFocus}
        onBlur={onBlurCallback}
        onChange={e => {
          onChange ? onChange(e.target.value, e.target.id) : null
        }}
        autoComplete={autoComplete ?? 'off'}
        // startAdornment={<InputAdornment position="start">{iconStart ?? ''}</InputAdornment>}
        endAdornment={<InputAdornment position="end">{iconEnd ?? ''}</InputAdornment>}
      />
      {error && (
        <FormHelperText sx={{ margin: 0 }} id={id + '_describe'}>
          <Typography
            p={theme => theme.spacing(1, 0)}
            component={'span'}
            variant="body2"
            color={'error.main'}
            fontWeight={400}
          >
            {error.toString()}
          </Typography>
        </FormHelperText>
      )}
      {helpText && <FormHelperText id={id + '_describe'}>{helpText}</FormHelperText>}
    </TextFiledContainer>
  )
}

export default TextField

const TextFiledContainer = styled(FormControl)(({ theme }) => ({
  width: '100%',
  margin: 'unset !important',
  '& input,textarea': {
    padding: theme.spacing(2.0625, 0),
    caretColor: theme.palette.primary.main,
    outline: 'none',
    '& :-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`
    },
    '& :-webkit-autofill:hover': {
      WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`
    },
    '& :-webkit-autofill:focus': {
      WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`
    },
    '& :-webkit-autofill:active': {
      WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`
    },
    '::selection': {
      backgroundColor: theme.palette.primary.main,
      color: 'background.paper'
    }
  },
  '& fieldset': {
    ':hover': {
      borderColor: 'transparent',
      outline: 'none'
    }
  }
}))

/**
 *
 *


 // Phần import

 import TextField from "components/TextField";
 import {
 lengthLessThan,
 lengthMoreThan,
 notEmpty,
 useField,
 useForm,
 } from "@shopify/react-form";




 # Phần khai báo

 const useFields = {
 title: useField<string>({
 value: 'defaultName',
 validates: [
 notEmpty('Trường này không được để trống.'),
 lengthLessThan(100, "Không được dài hơn 100 ký tự."),
 lengthMoreThan(2, "Tên phải dài hơn 2 ký tự.")
 ],
 })
 }


 const {
 fields,
 submit,
 submitting,
 dirty,
 reset,
 submitErrors,
 makeClean,
 } = useForm({
 fields: useFields,
 async onSubmit(values) {
 try {
 # dispatch ở đây, lấy value ra bằng cách values.tên trường
 alert(values.title);
 return { status: "success" };
 } catch (e: any) {
 console.error(`Submit error`, e);
 const message =
 e?.response?.data?.title ?? "Undefined error. Try again!";
 const field = e?.response?.data?.errorKey ?? "base";
 return { status: "fail", errors: [{ field, message }] };
 }
 },
 });



 # nơi gọi form ... được định nghĩa trên useFields

 <TextField
 label={"Tiêu đề"}
 maxLength={100}
 type="search"
 requiredIndicator
 {...fields.title}
 />



 #trong trường hợp không chạy qua textField, thì làm như sau:

 Lưu ý: value, onChange

 <input
 value={fields.title.value}
 onChange={e => fields.title.onChange(e.target.value) }
 />

 <p className="error_display">{fields.title.error}</p>




 * Lưu ý các constant:
 - dirty : người dùng chỉnh gì chưa, để cho phép lưu hoặc update ....
 - submit: Function callback, dùng cho nút hoặc form.
 - reset: Xóa trắng mọi form
 - makeClean: xóa trắng ...
 */
