//Library
import { useCallback, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

//Components
import ScrollToTop from 'components/ScrollToTop'
import EventTrigger from 'components/eventTrigger'
import Theme404 from './layout/404'
import AppToast from 'components/AppToast'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { public_route, separate_route as SeparateRoute } from './config/router-define'
import {
  checkJoinChannel,
  getCurrentUserData,
  getDefaultChannel,
  getLevelsChannel,
  getUserPermission,
  setLoadingDoneFirstTime
} from 'store/user.store.reducer'
import { clearError } from 'store/global.warning.store.reducer'

//Helper
import helpers from 'helpers'

//Css
// Styles must use direct files imports
import { getThemeByMode } from 'styles/themes'
import 'yet-another-react-lightbox/styles.css'
import MainLayout from 'layouts/MainLayout'
import { socket } from 'config/socket.io'
import LogoutModal from 'components/LogoutModal'
import { AUTH_SCREEN, GAMIFA_PRIMARY_COLOR } from 'constant/index'
import { lightPalette } from './styles/themes/palettes/defaultPalete'
import { colorGenerator } from './styles/utils/colorPaletteGenerator'
import ChannelSettingLayout from 'layouts/ChannelSettingLayout'
import ChannelSettingsInformation from 'entities/channel-settings/information'
import { channelInfo } from 'entities/channel-settings/store/channel.slice'
import PostCategory from 'entities/channel-settings/post-category'
import PointConfigurations from 'entities/channel-settings/point-configurations'
import ChannelSettingsAdvance from 'entities/channel-settings/advance'
import CapabilityChannel from 'entities/channel-settings/Capability'
import DisplaySystem from 'entities/channel-settings/display-system'
import { ROUTER } from 'constant/router'
// import { useNavigate } from 'react-router'

const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '')

export default function App() {
  const channel_data = useAppSelector(channelInfo)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)
  const errorMessage = useAppSelector(state => state.global_notification.errorMessage)
  const dispatch = useAppDispatch()

  const getDataForDisplay = useCallback(async () => {
    let hash = window.location.hash
    if (hash && hash.includes('authentication_hash')) {
      try {
        let originHash = hash.slice(1)
        let params = new URLSearchParams(originHash)
        let authentication_hash = params.get('authentication_hash')
        if (authentication_hash) {
          localStorage.setItem('session', authentication_hash)
          document.cookie = `session=${authentication_hash};path=/;SameSite=Lax`
        }
        window.history.replaceState(null, 'Home', window.location.pathname)
      } catch (e) {}
    }

    try {
      const isJoinChannel = localStorage.getItem('is_join_channel')
      if (!!localStorage.getItem('session')) {
        if (isJoinChannel === 'true') {
          await dispatch(getCurrentUserData())
        }
      } else {
        dispatch(setLoadingDoneFirstTime())
      }
      await dispatch(getDefaultChannel())
      dispatch(getUserPermission()) // new way to get permission
    } catch (e) {}
  }, [])

  useEffect(() => {
    if (!!localStorage.getItem('session')) {
      if (helpers.isEmpty(channel_data)) return
      const isJoinChannel = localStorage.getItem('is_join_channel')
      if (isJoinChannel) {
        if (isJoinChannel !== 'true') {
          if (!window.location.pathname.includes('/channel/join')) {
            window.location.href =
              window.location.protocol + '//' + window.location.host + `/channel/join`
          }
        }
      } else {
        dispatch(checkJoinChannel())
          .unwrap()
          .then(res => {
            if (res?.data?.is_join)
              return (window.location.href = window.location.protocol + '//' + window.location.host)
            return (window.location.href =
              window.location.protocol + '//' + window.location.host + `/channel/join`)
          })
          .catch(error => {
            console.log('checkJoinChannel Error >>>', error)
          })
      }
    }
  }, [channel_data])

  useLayoutEffect(() => {
    getDataForDisplay()
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(channel_data)) return
    dispatch(getLevelsChannel())
  }, [channel_data])

  useEffect(() => {
    setTimeout(() => {
      socket.io.opts.extraHeaders = {
        'X-Authorization': localStorage.getItem('session')
      }
      socket.connect()
    }, 2000)

    return () => {
      socket.disconnect()
    }
  }, [is_user_logged_in])

  useEffect(() => {
    if (channel_data?.country) {
      localStorage.setItem(
        'languageChannel',
        ['VN', 'HK'].includes(channel_data?.country?.toUpperCase()) ? 'vi' : 'en'
      )
    }
  }, [channel_data?.country])

  useEffect(() => {
    if (
      channel_data?.channel_public_status &&
      channel_data?.channel_public_status === 'private' &&
      !localStorage.getItem('session') &&
      !AUTH_SCREEN.some(item => window.location.pathname.includes(item))
    ) {
      window.location.href = window.location.origin + ROUTER.AUTH.LOGIN
    }
  }, [
    channel_data?.channel_public_status,
    channel_data?.facebook_login_client_id,
    channel_data?.google_login_client_id,
    is_user_logged_in
  ])

  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const hashURL = String(hash || ' ').replace('#', '')
      let _url = new URLSearchParams(hashURL)
      const referrer_code = _url.get('referrer_code') || ''
      if (referrer_code) {
        localStorage.setItem('referrer_code', referrer_code)
      }
    }
  }, [window.location])

  useEffect(() => {
    if (!errorMessage) return
    dispatch(clearError())
  }, [errorMessage])

  useEffect(() => {
    if (!channel_data?.channel_primary_color) return
    localStorage.setItem(
      'primary_color',
      helpers.replaceLastOccurrencesColor(channel_data?.channel_primary_color, 'ff', '')
    )
  }, [channel_data?.channel_primary_color])

  const chanelColor = channel_data?.channel_primary_color
    ? helpers.replaceLastOccurrencesColor(channel_data?.channel_primary_color, 'ff', '')
    : GAMIFA_PRIMARY_COLOR
  const paletteGenerated = {
    ...lightPalette,
    ...colorGenerator(chanelColor, 'primary')
  }

  const currentTheme = createTheme({
    ...getThemeByMode('light', {
      lightPalette: paletteGenerated
    })
  })

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL || baseHref}>
        <ScrollToTop />
        <EventTrigger />
        <>{createPortal(<AppToast />, document.body)}</>

        {/* Logout Modal */}
        <LogoutModal />

        {/* <BlockWebOnMobileModal channelData={channel_data} showModal={helpers.isMobile()} /> */}

        <Routes>
          <Route element={<MainLayout />}>
            {public_route.map((Routex, index) => (
              <Route key={`public_${index}`} path={Routex.path} element={<Routex.main />} />
            ))}
            <Route path="/channel-settings" element={<ChannelSettingLayout />}>
              <Route
                path="/channel-settings/information"
                element={<ChannelSettingsInformation />}
              />
              <Route path="/channel-settings/categories" element={<PostCategory />} />
              <Route
                path="/channel-settings/point-configurations"
                element={<PointConfigurations />}
              />
              <Route path="/channel-settings/display-system" element={<DisplaySystem />} />
              <Route path="/channel-settings/capability" element={<CapabilityChannel />} />
              <Route path="/channel-settings/advance" element={<ChannelSettingsAdvance />} />
            </Route>
          </Route>

          {SeparateRoute.map((Routex, index) => (
            <Route key={index} path={Routex.path} element={<Routex.main />} />
          ))}

          <Route key={99999} path="*" element={<Theme404 includeHeader />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
