import './media/courses.list.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TypedCourse, TypedCourseForCategory } from 'entities/courses/courses.interface'
import helpers from 'helpers/index'
import { Link } from 'react-router-dom'

import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import {
  getListCourseJoined,
  getListCoursesForCategory
} from 'entities/courses/services/courses.service'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import BigLayout from '../../layouts/bigLayout'
import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  Paper,
  Stack,
  Typography,
  styled
} from '@mui/material'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import SearchComponent from 'components/SearchComponent'
import { CheckInactiveIcon, PlusIcon } from 'icons'
import imageDefault from 'media/images/image_default.png'
import SwiperSlice from 'components/SwiperSlice'
import CoursesItem from './components/courses.item'
import Capacity from 'components/Capacity'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import CoursesListSkeleton from './skeletons/courses.list.skeleton'
import { formatString } from 'entities/support/utils'
import ComponentEmpty from 'components/componentEmpty'
import { useGetThumbnail } from 'hooks/useGetThumbnail'
import ReactPlayer from 'react-player'

export default function CourseList() {
  const rootSearchPath = window.location.search
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || {}

  const [courseJoinedData, setCourseJoinedData] = useState<TypedCourse[]>([])

  const {
    data,
    hasMore,
    totalItems,
    paramRequest,
    refresh,
    loadMore,
    isRefreshing,
    updateParamRequest
  } = useInfiniteScroll<TypedCourseForCategory>(
    {
      search: '',
      limit: 12,
      ...StringQuery
    },
    getListCoursesForCategory
  )

  const isEmptyCourse = useMemo(() => {
    return data.every(data => helpers.isEmpty(data?.courses) === true)
  }, [data])

  const onChangeSearch = useCallback((value: string) => {
    updateParamRequest({ search: value })
  }, [])

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(paramRequest)
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Courses', '/courses' + buildURLSearch)
    }
  }, [paramRequest])

  useEffect(() => {
    getListCourseJoined({ limit: 6, order_by: 'DESC' })
      .then(res => {
        setCourseJoinedData([...res?.data])
      })
      .catch(error => {
        console.log(`getListCourseJoined error`, error)
      })
  }, [])

  return (
    <CoursesContainer>
      <_Helmet title={`${__('course_list')} ${`${totalItems}`} ${__('course_list_lesson')}`} />
      <BigLayout>
        <Stack>
          <BreadcrumbComponent
            breadcrumbName={{
              '/courses': __('courses')
            }}
          />
        </Stack>
      </BigLayout>

      <Paper className="banner">
        <BigLayout>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
            <Stack>
              <Typography
                variant="h3"
                color={'background.paper'}
                textTransform={'none'}
                fontWeight={700}
              >
                {__('courses')}
              </Typography>
              <Typography
                variant="subtitle2"
                color={'background.paper'}
                textTransform={'none'}
                fontWeight={400}
              >
                {__('course_education_content')}
              </Typography>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <SearchComponent
                value={paramRequest?.search || ''}
                onChange={onChangeSearch}
                placeholder={__('course_upload_search_placeholder')}
              />
              <Capacity current_user_can={'boss'}>
                <Button
                  component={Link}
                  to="/courses/update"
                  sx={{
                    background: theme => theme.palette.background.paper,
                    ':focus, :hover': {
                      background: theme => theme.palette.background.paper
                    }
                  }}
                  startIcon={<PlusIcon sx={{ color: theme => theme.palette.primary.main }} />}
                >
                  <Typography textTransform={'none'} fontWeight={600} color="primary.main">
                    {__('course_add_title')}
                  </Typography>
                </Button>
              </Capacity>
            </Stack>
          </Stack>
        </BigLayout>
      </Paper>

      <Box>
        <BigLayout>
          <Stack gap={3}>
            {!helpers.isEmpty(courseJoinedData) && (
              <Paper
                sx={{
                  marginTop: theme => theme.spacing(-16.25),
                  p: theme => theme.spacing(3),
                  border: theme => `1px solid ${theme.palette.divider}`
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" textTransform={'none'} noWrap>
                    {__('course_joined')}
                  </Typography>
                  <Button
                    component={Link}
                    to="/courses/course-joined"
                    variant="text"
                    sx={{ textTransform: 'none' }}
                  >
                    {__('header_see_all')}
                  </Button>
                </Stack>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2}>
                  {courseJoinedData?.map((_item: TypedCourse, idx: number) => (
                    <React.Fragment key={`courses_joined_item_${idx}`}>
                      <CoursesJoinedItem courseData={_item} />
                    </React.Fragment>
                  ))}
                </Box>
              </Paper>
            )}

            <InfiniteScrollContainer
              isRefreshing={isRefreshing}
              dataLength={data.length}
              next={loadMore}
              hasMore={hasMore}
              refreshFunction={refresh}
              skeleton={<CoursesListSkeleton />}
              showEndMessage={Boolean(!data.length)}
              textEmptyMessage={formatString(__('data_empty'), `${__('courses').toLowerCase()}`)}
            >
              {isEmptyCourse ? (
                <ComponentEmpty text="" />
              ) : (
                <>
                  {data.map((courseData: TypedCourseForCategory, index: number) => (
                    <React.Fragment key={`course_${index}`}>
                      {!helpers.isEmpty(courseData?.courses) && (
                        <Stack gap={2} mb={1}>
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Typography variant="h6" textTransform={'none'} noWrap>
                              {courseData?.course_category_title || ''}
                            </Typography>
                            <Button
                              component={Link}
                              to={`/courses/category/${courseData?._id}`}
                              variant="text"
                              sx={{ textTransform: 'none' }}
                            >
                              {__('header_see_all')}
                            </Button>
                          </Stack>
                          <SwiperSlice
                            listItem={courseData?.courses}
                            renderBodyItem={data => (
                              <CoursesItem
                                course={data}
                                categoryName={courseData?.course_category_title}
                              />
                            )}
                          />
                        </Stack>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </InfiniteScrollContainer>
          </Stack>
        </BigLayout>
      </Box>
    </CoursesContainer>
  )
}

const CoursesContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3.75),
  '& .banner': {
    paddingTop: theme.spacing(5.5),
    width: '100%',
    height: theme.spacing(31.125),
    backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: theme.palette.primary.main }))}')`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }
}))

const CoursesJoinedItem = ({ courseData }: { courseData: TypedCourse }) => {
  const { _id, avatar, title, total_view = 0, module_child_count } = courseData

  const { ref, getThumbnailUrl } = useGetThumbnail()

  const [percentComplete] = useState<number>(
    module_child_count == 0
      ? 0
      : Math.min(
          100,
          Math.max(0, Math.round((Number(total_view || 0) * 100) / Number(module_child_count || 1)))
        )
  )

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    const isComplete = props?.value === 100
    return (
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Box
          sx={{
            flex: 1,
            '& .progress': {
              background: theme => theme.palette.success.main,
              '& span': { background: theme => theme.palette.success.main }
            }
          }}
        >
          <LinearProgress
            variant="determinate"
            className={isComplete && 'progress'}
            sx={{
              height: theme => theme.spacing(0.625),
              borderRadius: theme => theme.spacing(1.5)
            }}
            {...props}
          />
        </Box>
        {isComplete ? (
          <CheckInactiveIcon
            sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
            color="success"
          />
        ) : (
          <Typography variant="body2" color="text.secondary">
            {`${total_view || 0}/${module_child_count || 1}`}
          </Typography>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={`/study/${_id}`}
      direction={'row'}
      gap={2}
    >
      {!avatar?.media_mime_type?.includes('video') ? (
        <Box
          ref={ref}
          component={'img'}
          src={getThumbnailUrl(avatar?.media_url || imageDefault)}
          sx={{
            width: theme => theme.spacing(13.375),
            height: theme => theme.spacing(7.5),
            objectFit: 'cover',
            borderRadius: theme => theme.spacing(1.5)
          }}
        />
      ) : (
        <Box
          ref={ref}
          component={ReactPlayer}
          src={getThumbnailUrl(avatar?.media_url)}
          sx={{
            width: theme => theme.spacing(13.375),
            height: theme => theme.spacing(7.5),
            objectFit: 'cover',
            borderRadius: theme => theme.spacing(1.5)
          }}
        />
      )}

      <Stack justifyContent={'space-between'} flex={1}>
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <Typography variant="caption" color="text.secondary">
            {title || ''}
          </Typography>
          <Box
            sx={{
              width: '1px',
              height: '100%',
              background: theme => theme.palette.divider
            }}
          />
          <Typography variant="caption" color="text.secondary">
            {`${__('mentor_commission_channel_owner')} ${courseData?.user_id?.display_name || ''}`}
          </Typography>
        </Stack>
        <Typography variant="subtitle2" fontWeight={600} noWrap>
          {title || ''}
        </Typography>
        <LinearProgressWithLabel value={percentComplete || 0} />
      </Stack>
    </Stack>
  )
}
