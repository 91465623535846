//Library
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Stack, Typography, keyframes, styled } from '@mui/material'

//Component
import _Helmet from 'components/helmet'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { checkJoinChannel, getCurrentUserData, logout } from 'store/user.store.reducer'

//Interface
import TypedChannel from 'interfaces/channel.interface'

//Helpers
import __ from 'languages/index'

//Image
import backgroundSuccess from 'entities/channel/media/images/background_success.png'
import background from 'entities/channel/media/images/background.png'

import { formatString } from 'entities/support/utils'

export default function JoinChannel() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

  const reloadPage = useCallback(() => {
    dispatch(checkJoinChannel())
      .unwrap()
      .then(res => {
        if (res?.data?.is_join) {
          dispatch(getCurrentUserData())
          navigate('/', { replace: true })
        }
      })
      .catch(error => {
        console.log('checkJoinChannel Error >>>', error)
      })
  }, [channel_data])

  useEffect(() => {
    const isJoinChannel = localStorage.getItem('is_join_channel')
    if (isJoinChannel === 'true') return navigate('/')
  }, [])

  return (
    <>
      <_Helmet title="channel_join" />
      <JoinChannelContainer id="joinChannel">
        <Box id={'background_svg'} sx={{ backgroundImage: `url(${background})` }} />
        <Box id={'background_svg_default'} />

        <Stack id={'box_form'}>
          <Stack maxWidth={theme => theme.spacing(76)} spacing={3}>
            <Stack>
              <Box component={'img'} src={backgroundSuccess} alt="background" />
              <Stack textAlign={'center'} spacing={2} p={theme => theme.spacing(0, 2)}>
                <Typography fontSize={theme => theme.spacing(5)} fontWeight={'bold'}>
                  {__('successful')}
                </Typography>
                <Typography variant="subtitle1">
                  {formatString(
                    __('channel_join_community_success'),
                    channel_data?.channel_name || ''
                  )}
                </Typography>
                <Typography variant="subtitle2" textTransform={'none'} fontWeight={300}>
                  {__('channel_join_community_text_support')}
                </Typography>
                <Box justifyContent={'center'}>
                  <Button onClick={reloadPage} variant="outlined">
                    <Typography textTransform={'none'} fontWeight={600}>
                      {__('channel_join_btn_recheck')}
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={1}
              p={theme => theme.spacing(3, 0)}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography textTransform={'none'}>{__('channel_join_sub_text')}</Typography>
              <Button variant="text" sx={{ p: 0 }} onClick={() => dispatch(logout())}>
                <Typography textTransform={'none'} color="text.primary" fontWeight={600}>{`${__(
                  'header_logout'
                )}!`}</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </JoinChannelContainer>
    </>
  )
}

const JoinChannelContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  '& #background_svg': {
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& #box_form': {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`
