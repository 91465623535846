import React, { useCallback, useState } from 'react'
import { IconButton, Modal, Stack, Typography } from '@mui/material'
import __ from 'languages/index'
import { TypedCategory } from 'interfaces/request.interface'
import { CloseIcon } from 'icons'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import AddCategoryForm from './AddCategoryForm'
import {
  useDeleteCategoryMutation,
  useGetPostCategoriesQuery
} from 'entities/homeNew/store/homeApi'
import ConfirmModal from 'components/ConfirmModal'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import CategoryTable from 'entities/channel-settings/post-category/CategoryTable'
import { configTypeCategory, EnumCategory } from 'entities/channel-settings/post-category/categoryConfig'
import { deleteCategoryNews, getNewsToCategory } from 'entities/news/services'
import { deleteCategoryCourse, getCategoryCourse } from 'entities/channel-settings/service'

const getKeys = (obj: Record<string, any>) => Object.keys(obj)

const PostCategory = () => {
  const [selectedCategory, setSelectedCategory] = useState<{
    action: 'Delete' | 'Edit' | 'ADD'
    payload: TypedCategory
  }>(null)
  const [listCategory, setListCategory] = useState<{
    type: EnumCategory
    value: TypedCategory[]
  }>(null)

  const [listCategoryPost, setListCategoryPost] = useState<TypedCategory[]>(null)
  const [listCategoryCourse, setListCategoryCourse] = useState<TypedCategory[]>(null)
  const [listCategoryNews, setListCategoryNems] = useState<TypedCategory[]>(null)


  const [type, setType]= useState<{
    isAdd: boolean,
    value: EnumCategory
  }>(null)
  const [isLoading, setIsLoading]= useState<boolean>(false)
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false)

  const { data: category, isLoading: fetchingCategories, isFetching } = useGetPostCategoriesQuery()

  const [deleteCategory] = useDeleteCategoryMutation()

  const toast = useToast()
  const closeAddForm = () => {
    setType({
      isAdd: false,
      value: null
    })
  }

  const openAddForm = (type: EnumCategory) => {
    setType({
      isAdd: true,
      value: type
    })
  }

  const openDeleteForm = (category: TypedCategory, type:EnumCategory) => {
    setSelectedCategory({
      action: 'Delete',
      payload: category
    })
    setType({
      isAdd: false,
      value: type
    })
  }

  const closeDeleteForm = () => {
    setSelectedCategory({
      action: 'Delete',
      payload: null
    })
  }

  const handleDeleteCategory = useCallback( async () => {
    try {
      if(type?.value === EnumCategory.POST && selectedCategory?.action === 'Delete'){
        await deleteCategory({
          _id: selectedCategory.payload._id,
        }).unwrap()
        toast.show({
          type: EnumTypeToast.Success,
          content: __('channel_setting_delete_category_success')
        })
        getCategoryDisplay(type?.value)
      }
      if(type?.value === EnumCategory.COURSE && selectedCategory?.action === 'Delete'){
        deleteCategoryCourse(selectedCategory.payload._id)
          .then(res => {
            getCategoryDisplay(type?.value)
            toast.show({
              type: EnumTypeToast.Success,
              content: __('channel_setting_delete_category_success')
            })
          })
          .catch(err => {
            console.log('deleteCategoryCourse error', err)
            toast.show({
              content: `${err}}`,
              type: EnumTypeToast.Error
            })
          })
      }
      if(type?.value === EnumCategory.NEWS && selectedCategory?.action === 'Delete'){
        deleteCategoryNews(selectedCategory.payload._id)
          .then(res => {
            getCategoryDisplay(type?.value)
            toast.show({
              type: EnumTypeToast.Success,
              content: __('channel_setting_delete_category_success')
            })
          })
          .catch(err => {
            console.log('deleteCategoryNews error', err)
            toast.show({
              content: `${err}}`,
              type: EnumTypeToast.Error
            })
          })
      }
      closeDeleteForm()
    } catch (err) {
      console.log(err)
    }
  },[selectedCategory,type?.value])


  const getListCategoryPost = useCallback(()=>{
    setIsLoading(fetchingCategories)
    setListCategoryPost( [
      ...category?.map(item => ({
        ...item,
        category_visibility: item?.public_status,
      }))
    ])
  },[category, isFetching])


  const  getCategoryDisplay = useCallback(
    async (categoryType: EnumCategory)=>{
      if(categoryType === EnumCategory.POST && !!category){
        getListCategoryPost()

      }
      if(categoryType === EnumCategory.COURSE){
        getCategoryCourse({})
          .then(res=>{
            setListCategoryCourse([
              ...res?.data?.map(item => ({
                ...item,
                category_title: item?.course_category_title,
              }))
            ])
          })
          .catch(error => {
            console.log('getCategoryCourse error', error)
          })
      }
      if(categoryType === EnumCategory.NEWS){
        setIsLoading(true)
        getNewsToCategory({})
          .then(res => {
            setListCategoryNems(
              [
                ...res?.data?.map(item => ({
                  ...item,
                  category_title: item?.news_category_name,
                }))
              ]
            )
            setIsLoading(false)
          })
          .catch(error => {
            console.log('getNewsToCategory error', error)
          })

      }
    },[isFetchingData]
  )


  return (
    <Stack gap={2}>
      <CategoryTable
        listCategory={listCategoryPost}
        isLoading={isLoading}
        isFetching={isFetching}
        getCategoryDisplay={getCategoryDisplay}
        categoryType={{
          key: EnumCategory.POST,
          title: __("channel_setting_title_post_categories")
        }}
        openAddForm = {openAddForm}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        openDeleteForm={openDeleteForm}
      />
      <CategoryTable
        listCategory={listCategoryCourse}
        isLoading={isLoading}
        isFetching={isFetching}
        getCategoryDisplay={getCategoryDisplay}
        categoryType={{
          key: EnumCategory.COURSE,
          title: __("channel_setting_title_course_categories")
        }}
        openAddForm = {openAddForm}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        openDeleteForm={openDeleteForm}
      />
      <CategoryTable
        listCategory={listCategoryNews}
        isLoading={isLoading}
        isFetching={isFetching}
        getCategoryDisplay={getCategoryDisplay}
        categoryType={{
          key: EnumCategory.NEWS,
          title: __("channel_setting_title_news_categories")
        }}
        openAddForm = {openAddForm}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        openDeleteForm={openDeleteForm}
      />

      <Modal
        open={type?.isAdd}
        onClose={closeAddForm}
      >
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: '600px'
          }}
        >
          <ModalHeader direction={'row'}>
            <Typography className="title">{__('channel_setting_add_category')}</Typography>
            <IconButton onClick={closeAddForm}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            <AddCategoryForm getCategoryDisplay={getCategoryDisplay} categoryType={type} onClose={closeAddForm} />
          </ModalBody>
        </ModalContainer>
      </Modal>
      <ConfirmModal
        open={selectedCategory?.action === 'Delete' && selectedCategory?.payload !== null}
        onClose={closeDeleteForm}
        onConfirm={handleDeleteCategory}
        title={__('channel_setting_modal_confirm_title')}
      >
        <Typography>{__('channel_setting_modal_confirm_text')}</Typography>
      </ConfirmModal>
    </Stack>
  )
}

export default PostCategory
