import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
// import HappenningComponent from "./happenning.component"
// import UpComingComponent from "./upcoming.component"
// import FinishedComponent from "./finished.component"
import { useNavigate } from 'react-router-dom'
import TabSearch from '../tab.search'
// import ChallengeSearch from "./challenge.search"
import Capacity from 'components/Capacity'
import __ from 'languages/index'
import { Box, Button, IconButton, InputBase, Stack, Typography, styled } from '@mui/material'
import { PlusIcon, SearchIcon } from 'icons'
import debounce from 'lodash.debounce'
import { TypedChallengeItem } from 'entities/challenge/challenge.interface'
import {
  useJoinChallengeMutation,
  useLeaveChallengeMutation
} from 'entities/challenge/store/challengeApi'
import { useAppDispatch, useAppSelector } from 'config/store'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { deleteChallenge, setCookbook } from 'entities/challenge/challenge.store.reducer'
import ChallengeItem from '../challenge.item'
import CoursesListSkeleton from 'entities/courses/skeletons/courses.list.skeleton'
import DeleteConfirmModal from 'components/deleteConfirmModal'
import RenderView from 'components/RenderView'
import InfiniteList from 'components/InfiniteList'
import { Row } from 'react-bootstrap'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { IQueryParams } from 'config/reducer.utils'
import { getListChallenge } from 'entities/challenge/services'
import { formatString } from 'entities/support/utils'
import BreadcrumbComponent from 'components/BreadcrumbComponent'

export enum TabPaneChallengeData {
  PRESENT = 'present',
  UPCOMMING = 'future',
  FINISHED = 'past'
}
const DATA = [
  { _id: TabPaneChallengeData.PRESENT, title: `${__('challenge_happening')}` },
  { _id: TabPaneChallengeData.UPCOMMING, title: `${__('challenge_upcoming')}` },
  { _id: TabPaneChallengeData.FINISHED, title: `${__('challenge_finished')}` }
]
const LIMIT = 20

const ChallengeListComponent = props => {
  const navigate = useNavigate()
  const toast = useToast()
  const dispatch = useAppDispatch()
  // State
  const [currentSelected, setCurrentSelected] = useState<TabPaneChallengeData>(
    TabPaneChallengeData.PRESENT
  )
  const [statusChallenge, setStatusChallenge] = useState<string>('')
  const deleteModalRef = useRef(null)
  const ref = useRef(null)

  // ====================================

  const [joinChallenge] = useJoinChallengeMutation()
  const [leaveChallenge] = useLeaveChallengeMutation()
  const account = useAppSelector(state => state.user.user_data)
  const {
    data,
    hasMore,
    isLoading,
    loadMore,
    isRefreshing,
    updateParamRequest,
    paramRequest,
    setParamRequest,
    updateItem,
    updateData
  } = useInfiniteScroll<
    IQueryParams & {
      challenge_status?: TabPaneChallengeData
    }
  >(
    {
      search: '',
      limit: LIMIT,
      order_by: 'DESC',
      challenge_status: TabPaneChallengeData.PRESENT
    },
    getListChallenge
  )
  // useEffect
  useEffect(() => {
    dispatch(setCookbook(null))
  }, [])
  // Function
  const _gotoAddChallenge = useCallback(() => {
    navigate('/challenge/cookbook')
  }, [])

  const _onChange = useCallback((tab: TabPaneChallengeData) => {
    setCurrentSelected(tab)
    updateParamRequest({
      challenge_status: tab
    })
  }, [])

  const _onDeleteItem = useCallback(
    (item: TypedChallengeItem) => {
      deleteModalRef.current.show(`${__('challenge_list_delete')}`, () => {
        dispatch(deleteChallenge({ _id: item?._id }))
          .unwrap()
          .then(res => {
            let content = `${__('challenge_list_delete_error')}`
            let type = EnumTypeToast.Error
            if (res?.data) {
              content = `${__('challenge_list_delete_success')}`
              type = EnumTypeToast.Success
              const newData = data.filter(i => i?._id !== item?._id)
              updateData(newData)
            }
            toast.show({
              title: `${__('notification')}`,
              content,
              type
            })
          })
      })
    },
    [data]
  )

  // ============================================

  const renderLoading = useMemo(() => {
    return <CoursesListSkeleton />
  }, [])

  const joinChallengeHandler = async (item: TypedChallengeItem) => {
    if (item?.is_join) {
      leaveChallenge({ challengeId: item?._id, userId: account?._id })
        .unwrap()
        .then(result => {
          updateItem({
            ...item,
            is_join: false
          })
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('challenge_detail_leave_success')}`
          })
        })
        .catch(err => {
          toast.show({
            type: EnumTypeToast.Error,
            content: `${__('challenge_detail_leave_failed')}`
          })
        })
    } else {
      await joinChallenge({ challengeId: item?._id })
        .unwrap()
        .then(result => {
          const isPending = result.official_status === 0
          updateItem({
            ...item,
            is_join: true
          })
          const content = isPending
            ? `${__('challenge_detail_join_failed')}`
            : `${__('challenge_detail_join_success')}`
          toast.show({
            type: EnumTypeToast.Success,
            content
          })
        })
    }
  }
  // ====================================
  const handleValueSearch = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      setParamRequest({
        ...paramRequest,
        challenge_status: '',
        search: e.target.value
      })
    }, 500)
  }, [])

  const renderItem = (item: TypedChallengeItem, ref) => {
    return (
      <ChallengeItem
        currentSelected={currentSelected}
        joinChallengeHandler={joinChallengeHandler}
        key={item?._id}
        item={item}
        onDelete={_onDeleteItem}
        isCurrent
        ref={ref}
      />
    )
  }

  return (
    <ChanllengeListContainer id="long_challenge">
      <BreadcrumbComponent
        breadcrumbName={{
          '/challenge': 'Challenge'
        }}
      />
      <Button hidden={true} ref={ref} onClick={loadMore} />
      <Stack flexDirection={'row'} className="header_title">
        <Typography textTransform={'none'} variant="h5" mt={2}>
          {__('challenge')}
        </Typography>
        <Stack flexDirection={'row'}>
          <Stack flexDirection={'row'} className="box_search">
            <IconButton
              aria-label="search"
              sx={{ padding: 0, marginRight: theme => theme.spacing(1) }}
            >
              <SearchIcon color="inherit" />
            </IconButton>
            <InputBase
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleValueSearch(e)
              }}
              sx={{
                width: '100%'
              }}
              placeholder={__('search_challenge')}
              inputProps={{ 'aria-label': __('search_challenge') }}
              className="search_input"
            />
          </Stack>
          {
            <Capacity current_user_can={'boss'}>
              <div className=" d-flex align-items-center justify-content-between">
                <Button
                  sx={{
                    height: theme => theme.spacing(7)
                  }}
                  className="btn-add-event"
                  onClick={_gotoAddChallenge}
                >
                  <PlusIcon
                    sx={{
                      width: theme => theme.spacing(2.5),
                      height: theme => theme.spacing(2.5),
                      marginRight: theme => theme.spacing(1)
                    }}
                  />
                  <Typography textTransform={'none'} fontWeight={500}>
                    {__('create-challenge')}
                  </Typography>
                </Button>
              </div>
            </Capacity>
          }
        </Stack>
      </Stack>
      <Box mt={2}>
        {DATA.map(item => {
          return (
            <Button
              variant="outlined"
              className={`btn_tab ${currentSelected === item._id && 'active'} `}
              key={item._id}
              onClick={() => {
                setStatusChallenge(item?.title)
                _onChange(item?._id)
              }}
            >
              <Typography textTransform={'none'}>{item.title}</Typography>
            </Button>
          )
        })}
        <Box mt={2}>
          <DeleteConfirmModal ref={deleteModalRef} />
          <RenderView
            view={
              <InfiniteList
                as={Row}
                list={data}
                isLoading={isLoading}
                renderItem={renderItem}
                hasNextPage={hasMore}
                onLoadMore={() => ref.current?.click()}
              />
            }
            isLoading={isRefreshing}
            isEmpty={data.length === 0}
            loader={renderLoading}
            textEmpty={formatString(
              __('data_empty'),
              `${__('challenge')} ${statusChallenge}`.toLowerCase()
            )}
          />
          {/* {
            currentSelected === 'present' && <HappenningComponent joinChallengeHandler={joinChallengeHandler} />
          }
          {
            currentSelected === 'future' && <UpComingComponent joinChallengeHandler={joinChallengeHandler} />
          }
          {
            currentSelected === 'finished' && <FinishedComponent joinChallengeHandler={joinChallengeHandler} />
          }
          {
            currentSelected == "search" && <ChallengeSearch joinChallengeHandler={joinChallengeHandler} />
          } */}
        </Box>
      </Box>
    </ChanllengeListContainer>
  )
}

export default ChallengeListComponent

const ChanllengeListContainer = styled(Box)(({ theme }) => ({
  '& .header_title': {
    justifyContent: 'space-between',

    '& .box_search': {
      background: '#fff',
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1, 2),
      border: '1px solid #E0E2E6'
    },

    '& .btn-add-event': {
      marginLeft: theme.spacing(2)
    }
  },

  '& .btn_tab': {
    border: '1px solid #E0E2E6',
    padding: theme.spacing(2, 3),
    color: '#000',
    '&:nth-child(2)': {
      margin: theme.spacing(0, 2)
    }
  },

  '& .active': {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}))
