import React, { useEffect, useState } from 'react'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { Box, Button, Stack } from '@mui/material'
import ColorSelector from 'components/ColorSelector'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelSetting } from '../store/channel.slice'
import { uploadFile } from 'entities/courses/services/courses.media.service'
import FileUploader from '../components/FileUploader'
import CreditConfigurations from 'entities/channel-settings/credit-configurations'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import TypedChannel from 'interfaces/channel.interface'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import TextField from 'components/TextFieldMUI'
import { notEmpty, useField, useForm } from '@shopify/react-form'

const ChannelSettingsInformation = () => {
  const channel = useAppSelector(channelInfo)
  const dispatch = useAppDispatch()
  const [logo, setLogo] = useState<FileList | File[]>(null)
  const [channelData, setChannelData] = useState<TypedChannel>(channel)
  const toast = useToast()

  const { fields , submit, dirty, submitting } = useForm({
    fields: {
      channel_name: useField<string>({
        value: channelData?.channel_name ?? '',
        validates: [
          notEmpty(`${__('channel_setting_channel_name')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('channel_setting_channel_name')}`
            }
          }
        ]
      }),
      channel_slogan: useField<string>({
        value: channelData?.channel_slogan ?? '',
        validates: []
      }),
      channel_description: useField<string>({
        value: channelData?.channel_description ?? '',
        validates: []
      }),
      channel_primary_color: useField<string>({
        value: channelData?.channel_primary_color ?? '',
        validates: []
      }),
      channel_avatar: useField<string>({
        value: channelData?.channel_avatar ?? '',
        validates: [
          notEmpty(`${__('channel_setting_channel_avatar')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('channel_setting_channel_avatar')}`
            }
          }
        ]
      }),
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateChannelSetting(values)
        )
          .unwrap()
          .then(res=>{
            setChannelData(res?.data)
            toast.show({
              content: `${__('channel_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(err => {
            console.log('err',err)
            toast.show({
              content: `${__('channel_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })


  const urlLogoPlaceholder = () => {
    if (logo) return URL.createObjectURL(logo[0])
    return channelData?.channel_avatar
  }

const handleUploadAvatar =   async ()=>{
  let uploadedLogo = null

  try{
    const formData = new FormData()
    Array.from(logo).forEach(file => {
      formData.append('file[]', file)
    })
    uploadedLogo = await uploadFile(formData)
  }catch (e:any){
    console.log('uploadedLogo', e)

  }
  fields.channel_avatar.onChange(uploadedLogo[0].callback?.media_url)

}
  useEffect( () => {
    if (!logo) return
    handleUploadAvatar()
  }, [logo])

  return (
    <Stack rowGap={2}>
      <ChannelSettingsContentContainer>
        <Stack gap={2} component="form" onSubmit={(e) =>{
          e.preventDefault()
          submit()
        }}>
          <Stack direction={'row'}>
            <ChannelSettingsTitle>{__('channel_information')}</ChannelSettingsTitle>
            <Button
              type={'submit'}
              disabled={!dirty || submitting}
              sx={{
                marginLeft: 'auto',
                textTransform: 'none',
                fontSize: theme => theme.spacing(2),
                minWidth: theme => theme.spacing(12)
              }}
            >
              {__('btn_save')}
            </Button>
          </Stack>
          <Stack direction="row" gap={2}>
            <Box
              sx={{ minWidth: theme => theme.spacing(16)}}
            >
              <FileUploader
                {...fields?.channel_avatar}
                acceptFiles="image/*"
                onUploaded={setLogo}
                placeholder={
                  (logo !== null || fields?.channel_avatar) && (
                    <Box
                      component={'img'}
                      src={urlLogoPlaceholder()}
                      sx={{
                        borderRadius: theme => theme.spacing(1.5),
                        width: theme => theme.spacing(16),
                        height: theme => theme.spacing(16)
                      }}
                    />
                  )
                }
              />
            </Box>
            <Stack gap={2} width={'100%'}>
              <TextField
                {...fields?.channel_name}
                label={__('label_channel_name')}
                placeholder={__('p_channel_name')}
              />
              <TextField
                {...fields?.channel_slogan}
                label={__('label_channel_slogan')}
                placeholder={__('p_channel_slogan')}
              />
            </Stack>
          </Stack>
          <TextField
            {...fields?.channel_description}
            multiline
            rows={3}
            maxLength={150}
            label={__('label_channel_description')}
            placeholder={__('p_channel_description')}
          />
          <Stack></Stack>
          <ColorSelector
            {...fields?.channel_primary_color}
            color={fields?.channel_primary_color?.value || ''}
            onChangeColor={color => {
              fields?.channel_primary_color.onChange(color)
            }}
          />
        </Stack>
      </ChannelSettingsContentContainer>
      <CreditConfigurations channel={channel}/>
    </Stack>
  )
}

export default ChannelSettingsInformation
