
export type CreateConfigLevelPayload = {
  level_number: string
  level_point: string
  title: string
}
export type TypeCategoryCourse = {
  _id?: string,
  course_category_title?: string,
  category_course_avatar?: string,
  category_visibility?: number,
}
export interface TypeDataLevel {
  createdAt: string
  level_number: string
  level_point: string
  title: string
  total_member: number
  updatedAt: string
  user_id: string
  __v: number
  _id: string
}
export enum ChannelStatus {
  PUBLIC = 'public',
  PRIVATE = 'private'
}
export enum ConfigVerify {
  EMAIL = 'email',
  SMS = 'sms'
}

export enum ConfigPayment {
  TRANSFER = 'transfer_info',
  VNPAY = 'vnpay_config'
}
