//Library
import { useCallback, useEffect, useMemo, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import dateandtime from 'date-and-time'

//Components
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import CoursesIncomeTable from './courses.income.table'
import CourseIncomeFilterProduct from './components/course.filter.product'
import CourseIncomeFilterTime from './components/course.filter.time'
import CourseCardIncome from './components/course.card.income'

//Stores
import { useAppDispatch, useAppSelector } from 'config/store'
import {
  getDataUserIncome,
  getTransactionCourseForAdmin,
  getTransactionCourseForUser
} from './courses.store.reducer'

//Interface
import { TypeDataProduct, TypeDataTime } from 'entities/mentor/mentor.interface'
import { TypeTransactionUserIncome } from './courses.interface'

//Image
import iconClock from './media/icon_clock.svg'
import iconProduct from './media/icon_products.svg'

//Scss
import './media/courses.income.scss'
import __ from 'languages/index'
import BigLayout from '../../layouts/bigLayout'
import { formatString } from 'entities/support/utils'
import ComponentEmpty from 'components/componentEmpty'
import SearchComponent from 'components/SearchComponent'
import { IQueryParams } from 'config/reducer.utils'
import { EnumRole } from 'constant/index'
import helpers from 'helpers'
import _Helmet from 'components/helmet'
import { Box, styled, Typography } from '@mui/material'

export default function CoursesIncome() {
  const dispatch = useAppDispatch()

  const user_data = useAppSelector(state => state.user.user_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [tabIndex, setTabIndex] = useState<string>("0");
  const [showModalFilterTime, setShowModalFilterTime] = useState<boolean>(false)
  const [showModalFilterProduct, setShowModalFilterProduct] = useState<boolean>(false)
  const [dataFilterProduct, setDataFilterProduct] = useState<TypeDataProduct[]>([])
  const [dataUserIncome, setDataUserIncome] = useState<Partial<TypeTransactionUserIncome>>({})
  const [dataTransaction, setDataTransaction] = useState({
    listData: [],
    totalData: 0
  })
  const [dataFilterTime, setDataFilterTime] = useState<TypeDataTime>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  })
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: '',
    type_system: 'system',
    // type_system: Number(tabIndex) ? "mentor" : "system",
    from: '',
    to: '',
    ref_type: 'course',
    ref_id: '',
    method: 'plus'
  })

  const handleChangeInputSearch = (value: string) => {
    setParams({ ...params, page: 1, search: value })
  }

  const handlePageChange = useCallback(
    (type: string) => {
      const page = type === 'next' ? params?.page + 1 : params?.page - 1
      setParams({ ...params, page: page })
    },
    [params]
    // [params, tabIndex]
  )

  const handleCloseModalFilterProduct = useCallback(() => {
    setShowModalFilterProduct(false)
  }, [])

  const handleSubmitFilterProduct = useCallback(
    (value: TypeDataProduct[]) => {
      let joinId = ''
      value.forEach(item => (joinId = `${joinId} ${joinId && ','} ${item?._id}`))
      setParams({ ...params, ref_type: 'course', ref_id: joinId?.trim() })
      setShowModalFilterProduct(false)
      setDataFilterProduct(value)
    },
    [dataFilterProduct, params]
  )

  const handleCloseModalFilterTime = useCallback(() => {
    setShowModalFilterTime(false)
  }, [])

  const handleConvertFilterTime = useCallback((start: any, end: any) => {
    if (!start && !end) return
    const startDate = new Date(
      `${dateandtime.format(new Date(start), 'YYYY-MM-DD')} 00:00:00`
    ).toISOString()
    const endDate = new Date(
      `${dateandtime.format(new Date(end), 'YYYY-MM-DD')} 23:59:59`
    ).toISOString()
    return { startDate, endDate }
  }, [])

  const handleSubmitFilterTime = useCallback(
    (value: TypeDataTime) => {
      const time = handleConvertFilterTime(value?.startDate, value?.endDate)
      setShowModalFilterTime(false)
      setParams({ ...params, from: time?.startDate, to: time?.endDate })
      setDataFilterTime(value)
    },
    [params]
    // [tabIndex, params]
  )

  const handleOpenModalFilter = useCallback((type: string) => {
    if (type === 'time') {
      setShowModalFilterTime(true)
    } else if (type === 'product') {
      setShowModalFilterProduct(true)
    }
  }, [])

  // const handleChangeTabs = useCallback(
  //   (key: string) => {
  //     setTabIndex(key);
  //     setTextSearch("");
  //     setDataFilterProduct([]);
  //     setDataFilterTime({
  //       startDate: undefined,
  //       endDate: undefined,
  //       key: "selection",
  //     });
  //     setParams({
  //       ...params,
  //       page: 1,
  //       type_system: Number(key) ? "mentor" : "system",
  //       search: "",
  //       from: "",
  //       to: "",
  //       ref_id: "",
  //       ref_type: "course",
  //     });
  //   },
  //   [params]
  // );

  const handleConvertTime = useCallback((value: any) => {
    return dateandtime.format(new Date(value), 'DD/MM/YYYY')
  }, [])

  const convertTextFilterProduct = useMemo(() => {
    let text = ''
    dataFilterProduct.forEach(item => (text = `${text} ${text && ','} ${item?.title}`))
    return text
  }, [dataFilterProduct])

  const handleGetTransactionForAdmin = useCallback(
    (params: IQueryParams) => {
      setIsLoading(true)
      dispatch(getTransactionCourseForAdmin(params))
        .unwrap()
        .then(res => {
          setDataTransaction({
            listData: res?.data,
            totalData: parseInt(res.headers['x-total-count'] || 1000, 10)
          })
          setIsLoading(false)
        })
        .catch(error => {
          setDataTransaction({
            listData: [],
            totalData: 0
          })
          setIsLoading(false)
          console.log(error)
        })
    },
    [params]
  )

  const handleGetTransactionForUser = useCallback(
    (params: IQueryParams) => {
      setIsLoading(true)
      dispatch(getTransactionCourseForUser(params))
        .unwrap()
        .then(res => {
          setDataTransaction({
            listData: res?.data,
            totalData: parseInt(res.headers['x-total-count'] || 1000, 10)
          })
          setIsLoading(false)
        })
        .catch(error => {
          setDataTransaction({
            listData: [],
            totalData: 0
          })
          setIsLoading(false)
          console.log(error)
        })
    },
    [params]
  )

  useEffect(() => {
    setIsLoading(true)
    dispatch(
      getDataUserIncome({
        type_ref: 'course',
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    )
      .unwrap()
      .then(res => {
        setDataUserIncome(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        setDataUserIncome({})
        setIsLoading(false)
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(user_data)) return
    if (user_data?.channelpermission?.permission?.indexOf(EnumRole.User) !== -1)
      return handleGetTransactionForUser(params)
    handleGetTransactionForAdmin(params)
  }, [params, user_data])

  return (
    <BigLayout>
      <CourseCardIncomeContainer>
        <_Helmet title="course_revenue" />
        <Box id="coursesIncome" className="coursesIncome_container">
          <Box color={'background.paper !important'} className="coursesIncome_first">
            <Typography color={'background.paper'} variant={'subtitle2'}>{__("course_revenue")}</Typography>
            <Box className="coursesIncome_first-statistical">
              <CourseCardIncome dataUserIncome={dataUserIncome} />
            </Box>
          </Box>

          <div className="coursesIncome__second">
            <span className="coursesIncome__history">{__('course_transaction_history')}</span>
            {/* <div className="courseIncome__tabs">
            {dataTabs?.map((item: any, idx: number) => (
              <div key={`tab_${idx}`}>
                <span
                  className={`courseIncome__item ${Number(tabIndex) === idx && "activeTab"}`}
                  onClick={() => handleChangeTabs(`${idx}`)}
                >
                  {item?.tabName}
                </span>
              </div>
            ))}
          </div> */}
            <div className="courseIncome__filterWrapper">
              <div className="courseIncome__filter">
                <div
                  className="courseIncome__filter--time"
                  onClick={() => handleOpenModalFilter('time')}
                >
                  <img src={iconClock} alt="icon-clock" />
                  <span className="courseIncome__filter--title">
                  {dataFilterTime?.startDate
                    ? `${handleConvertTime(dataFilterTime?.startDate)} - ${handleConvertTime(
                      dataFilterTime?.endDate
                    )}`
                    : `${__('course_filter_by_time')}`}
                </span>
                </div>
                <div
                  className="courseIncome__filter--product"
                  onClick={() => handleOpenModalFilter('product')}
                >
                  <img src={iconProduct} alt="icon-product" />
                  <span className="courseIncome__filter--title">
                  {dataFilterProduct?.length
                    ? convertTextFilterProduct
                    : `${__('course_filter_by_product')}`}
                </span>
                </div>
              </div>
              <SearchComponent
                placeholder={__('course_search')}
                value={params?.search || ''}
                onChange={handleChangeInputSearch}
              />
            </div>
          </div>

          {isLoading ? (
            <div className="bg-white p-4 rounded mt-4">
              <SettingMentorSkeleton />
            </div>
          ) : (
            <>
              {dataTransaction?.listData?.length ? (
                <Box className="coursesIncome__third">
                  <CoursesIncomeTable
                    data={dataTransaction?.listData}
                    index={'0'}
                    // index={tabIndex}
                  />

                  <Box
                    className="coursesIncome_third-pagination"
                    // className={
                    //   Number(tabIndex) ? "coursesIncome__third--paginationMentor" : "coursesIncome__third--pagination"
                    // }
                  >
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => handlePageChange('prev')}
                        disabled={params?.page === 1}
                      />
                      <Pagination.Item active>{params?.page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => handlePageChange('next')}
                        disabled={params?.page === (Math.ceil(dataTransaction?.totalData / 10) || 1)}
                      />
                    </Pagination>
                  </Box>
                </Box>
              ) : (
                <ComponentEmpty
                  text={formatString(__('data_empty'), `${__('course_revenue').toLowerCase()}`)}
                />
              )}
            </>
          )}

          {showModalFilterProduct && (
            <CourseIncomeFilterProduct
              data={dataFilterProduct}
              show={showModalFilterProduct}
              title={__('course_select_product')}
              onClose={handleCloseModalFilterProduct}
              onSubmit={handleSubmitFilterProduct}
            />
          )}

          {showModalFilterTime && (
            <CourseIncomeFilterTime
              data={dataFilterTime}
              show={showModalFilterTime}
              title={__('course_select_time')}
              onClose={handleCloseModalFilterTime}
              onSubmit={handleSubmitFilterTime}
            />
          )}
        </Box>
      </CourseCardIncomeContainer>
    </BigLayout>
  )
}
const CourseCardIncomeContainer = styled(Box)(({theme})=>({
  '& .coursesIncome_container':{
    width: '100%',
    height: '100%',
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,

  },
  '& .coursesIncome_first':{
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(2.75, 3.5),
    color: `${theme.palette.background.paper} !important`,

  },
  '& .coursesIncome_first-statistical':{
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: theme.spacing(1.25),
    color: theme.palette.background.paper,
    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      rowGap: theme.spacing(1.25)
    }
  },
  '& .coursesIncome_third-pagination':{
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    '& .pagination':{
      marginBottom: 'unset'
    },
    '& .active':{
      '& .page-link':{
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      }
    }

  }

}))
