import { Box, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import __ from 'languages/index'
import RenderView from 'components/RenderView'
import Table from 'entities/channel-settings/components/Table'
import React, { useState } from 'react'
import { StyledTableCell } from 'entities/channel-settings/components/StyledTableCell'
import RowActions from 'entities/channel-settings/components/RowActions'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import Select from 'components/Select'
import helpers from 'helpers/index'
import { TypeFeatures } from 'entities/channel-settings/display-system/index'
import DragAndDropIcon from '../../../icons/DragAndDropIcon'
import TableDrapDrop from 'entities/channel-settings/components/TableDrapDrop'


interface TypeDisplayFeature{
  listData: TypeFeatures[]
  isLoading?: boolean
  isEmpty?: boolean
}

const FeatureDisplay = ({ isLoading, isEmpty, listData }: TypeDisplayFeature)=>{
  const [editing, setEditing] = useState<any>(null)
  const [listFeature, setListFeature] = useState<TypeFeatures[]>(listData)
  const optionStatus = [
    {
      key: '0',
      value: 'Ẩn'
    },
    {
      key: '1',
      value: 'Hiển thị'
    }
  ]

  const handleOnChangeDisplay = (value: string)=> {
    setEditing({
      ...editing,
      status: value
    })
  }

  const handleUpdateCategory =()=>{}

  const renderStatus = (category_status: string)=>{
    if(!helpers.isEmpty(category_status)){
      return optionStatus?.find(status => category_status === status?.key)?.value
    }
    return optionStatus?.[0]?.value
  }


  const renderHeader = () => {
    return (
      <TableRow>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_table_category_stt")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_feature_original_name")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_feature_name")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_table_display")}</StyledTableCell>
        <StyledTableCell  sx={{ textAlign: 'end', whiteSpace: "nowrap"}}>{__("channel_setting_table_action")}</StyledTableCell>
      </TableRow>
    )
  }




  const renderBodyRow = (item: TypeFeatures, index: number) => {
    return (
      <>
        <TableCell sx={{textAlign: 'center'}} >
          <Typography variant={'body1'} sx={{fontWeight: 600, textTransform: 'none'}}>
            <DragAndDropIcon
              sx={{
                width: theme => theme.spacing(3),
                height: theme => theme.spacing(3),
                marginRight: theme => theme.spacing(1)
              }}
            />
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell>{item?.original_name}</TableCell>
        <TableCell>
          {item?.display_name === editing?.display_name? (
            <TextField
              sx={{
                maxWidth: 'fit-content'
              }}
              size="small"
              value={editing?.display_name}
              onChange={e => {
                setEditing({
                  ...editing,
                  display_name: e?.target.value
                })
              }}
              autoFocus
              label={__('channel_setting_feature_name')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography>{item?.display_name}</Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {item?.status === editing?.status ? (
            <Select
              onChangeValue={handleOnChangeDisplay}
              value={editing?.status}
              options={optionStatus}
              getItemValue={item => item?.key}
              renderItem={item => <Typography sx={{whiteSpace: "nowrap"}}>{item?.value}</Typography>}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ whiteSpace: "nowrap" }} >
                {renderStatus(item?.status ?? '')}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => setEditing(item)}
            editing={item?.display_name === editing?.display_name && item?.status === editing?.status}
            onConfirm={handleUpdateCategory}
          />
        </TableCell>
      </>
    )
  }

  return(
    <ChannelSettingsContentContainer>
      <Stack gap={2}>
        <ChannelSettingsTitle>{__("channel_setting_feature_display")}</ChannelSettingsTitle>
        <RenderView
          view={
            <TableDrapDrop
              data={listFeature}
              renderTableRow={renderBodyRow}
              renderHeader={renderHeader}
              setData={setListFeature}
            />
          }
          isLoading={isLoading}
          isEmpty={isEmpty}
        />
      </Stack>
      <Typography color={'text.secondary'} p={theme => theme.spacing(1,0)}>
        {__("channel_setting_feature_display_sub_text")}
      </Typography>
    </ChannelSettingsContentContainer>
  )
}
export default FeatureDisplay

