//Library
import { useAppDispatch, useAppSelector } from 'config/store'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  styled,
  keyframes
} from '@mui/material'

//Component
import SocialNetworkComponent from './component/social.login'
import _Helmet from 'components/helmet'

//Store
import { loginWithPassword } from 'store/auth.store.reducer'
import {
  checkJoinChannel,
  clearError,
  getCurrentUserData,
  getDefaultChannel,
  getUserPermission
} from 'store/user.store.reducer'
import __, { ___ } from 'languages/index'

//Image
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import imageDefault from 'media/images/image_default.png'

//Helpers
import helpers from 'helpers'

import { EyeIcon, EyeOffIcon, MobileIcon, SafetyClockIcon } from 'icons'
import TextFieldMUI from 'components/TextFieldMUI'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'
import { ROUTER } from 'constant/router'

/**
 * Check channel Status ở official_status trong getCurrentChannelInfo!
 *
 * @returns
 */

export default function LoginPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const checkMobile = helpers.isMobile()
  const channel_data = useAppSelector(state => state.user.channel_data)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleCheckJoinChannel = async () => {
    return await dispatch(checkJoinChannel())
      .unwrap()
      .then(res => {
        if (res?.data?.is_join) {
          dispatch(getCurrentUserData())
          navigate('/', { replace: true })
        } else {
          navigate(`/channel/join`)
        }
      })
      .catch(error => {
        console.log('checkJoinChannel Error >>>', error)
      })
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    // Implement your authentication logic here
    // For simplicity, this example checks if both username and password are not empty
    e.preventDefault()
    const formData = {
      user_password: password.trim(),
      user_input: username.trim(),
      device_type: 'website',
      _language: localStorage.getItem('languageChannel')
    }
    if (formData.user_input && formData.user_password) {
      // Successful login
      try {
        setIsLoading(true)
        let _loginWithPassword: any = await dispatch(loginWithPassword(formData))
        if (typeof _loginWithPassword.error !== 'undefined') {
          setError(_loginWithPassword.error.message || `${__('login_error_unknown')}`)
          setIsLoading(false)
          return
        }
        const response = _loginWithPassword?.payload?.data || ''
        const authCode = _loginWithPassword.payload.headers['x-authorization'] || ''

        document.cookie = `session=${authCode};path=/;SameSite=Lax`

        // SSO
        localStorage.setItem('session', authCode)
        setError('')
        dispatch(clearError())
        await helpers.sleep(500) // make sure anything should written to cookie and localStorage
        await dispatch(getDefaultChannel())
        await dispatch(getUserPermission())
        if (!response?.register_mode) {
          handleCheckJoinChannel()
        } else {
          if (response?.register_mode === 'sms') {
            if (!response?.user_verified_phone) {
              navigate('/validate-code#phone')
              localStorage.setItem('user_input_verify', JSON.stringify(response?.user_phone || ''))
            } else {
              handleCheckJoinChannel()
            }
          }
          if (response?.register_mode === 'email') {
            if (!response?.user_verified_email) {
              navigate('/validate-code#email')
              localStorage.setItem('user_input_verify', JSON.stringify(response?.user_email || ''))
            } else {
              handleCheckJoinChannel()
            }
          }
        }
      } catch (e: any) {
        // setError(e.message);
        console.log(e, '<<< Login Error ...')
      }
      setIsLoading(false)
    } else {
      // Display error message
      setError(`${__('login_error_enter_both_username_and_password')}`)
    }
  }

  return (
    <>
      <_Helmet title={'login'} />
      <LoginContainer id="loginPage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />

        {channel_data && (
          <Box id={'box_form'} className={checkMobile ? 'wrapper_mobile' : 'wrapper'}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                maxWidth: theme => theme.spacing(76),
                height: checkMobile ? '100%' : 'auto',
                borderRadius: checkMobile ? 0 : '12px',
                justifyContent: 'center'
              }}
            >
              <Box>
                {/*<div className="d-flex justify-content-end">*/}
                {/*  {CountryLanguage.map((language: TypeLanguage, index: number) => (*/}
                {/*    <button*/}
                {/*      key={`btn-language${index}`}*/}
                {/*      className="border-0 loginPage_language"*/}
                {/*      onClick={() => handleChooseLanguage(language?.name)}*/}
                {/*    >*/}
                {/*      <img src={language?.flag} className="w-100 h-100" />*/}
                {/*    </button>*/}
                {/*  ))}*/}
                {/*</div>*/}
                {error && <Alert severity="error">{error}</Alert>}
                <Box
                  display={'grid'}
                  rowGap={theme => theme.spacing(3)}
                  padding={theme => theme.spacing(3)}
                >
                  <Box display={'flex'} justifyContent={'center'}>
                    <Link to={'/'}>
                      <Avatar
                        sx={{
                          width: theme => theme.spacing(8.75),
                          height: theme => theme.spacing(8.75)
                        }}
                        src={channel_data?.channel_avatar || imageDefault}
                        alt="avatar_channel"
                      />
                    </Link>
                  </Box>
                  <Typography
                    variant="h5"
                    textTransform={'none'}
                    fontWeight={700}
                    textAlign={'center'}
                  >
                    {__('btn_login')}
                  </Typography>
                  {/*<Typography*/}
                  {/*  variant="subtitle2"*/}
                  {/*  fontWeight={400}*/}
                  {/*  color={'text.secondary'}*/}
                  {/*  textAlign={'center'}*/}
                  {/*  textTransform={'none'}*/}
                  {/*>*/}
                  {/*  {channel_data?.description || channel_data?.description || ''}*/}
                  {/*</Typography>*/}
                  <form onSubmit={handleLogin} className="form">
                    <TextFieldMUI
                      type="text"
                      placeholder={__('login_user_name_label')}
                      autoFocus
                      value={username || ''}
                      onChange={(value: string) => setUsername(value)}
                      iconStart={<MobileIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    />
                    <TextFieldMUI
                      type={showPassword ? 'text' : 'password'}
                      placeholder={__('login_password_label')}
                      value={password || ''}
                      onChange={(value: string) => setPassword(value)}
                      iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      iconEnd={
                        <IconButton
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          sx={{ p: 0 }}
                        >
                          {showPassword ? (
                            <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          ) : (
                            <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          )}
                        </IconButton>
                      }
                    />
                    <Button
                      disabled={isLoading}
                      type="submit"
                      sx={{ p: theme => theme.spacing(2.1875, 2) }}
                    >
                      <Typography textTransform={'none'}>
                        {isLoading ? __('btn_processing_login') : __('login')}
                      </Typography>
                    </Button>
                  </form>
                  <SocialNetworkComponent />
                  <Box>
                    <Typography sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {___('By log in, you agree to our {terms} and {policies}', {
                        terms: (
                          <>
                            &nbsp;
                            <Link to={'/tos'} className="link">
                              <Typography color={'text.primary'} fontWeight={600}>
                                {` ${__('terms_of_use')} `}
                              </Typography>
                            </Link>
                            &nbsp;
                          </>
                        ),
                        policies: (
                          <>
                            &nbsp;
                            <Link to={'/tos'} className="link">
                              <Typography color={'text.primary'} fontWeight={600}>
                                {` ${__('policies')} `}
                              </Typography>
                            </Link>
                          </>
                        )
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Paper className={`footer ${checkMobile && 'mobile'}`}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Typography>{__('login_register_account_text')}</Typography>
                  <Button variant="text" sx={{ p: 0 }}>
                    <Link to={ROUTER.AUTH.REGISTER} style={{ textDecoration: 'none' }}>
                      <Typography textTransform={'none'} color="primary.main" fontWeight={600}>
                        {__('register')}
                      </Typography>
                    </Link>
                  </Button>
                </Box>
                <Button variant="text" sx={{ p: 0 }}>
                  <Link to={ROUTER.AUTH.FORGOT_PASSWORD} style={{ textDecoration: 'none' }}>
                    <Typography textTransform={'none'} color="primary.main" fontWeight={600}>
                      {__('login_forgot_password')}
                    </Typography>
                  </Link>
                </Button>
              </Paper>
            </Paper>
          </Box>
        )}
      </LoginContainer>
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`

const LoginContainer = styled(Box)(({ theme }) => ({
  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,

  height: '100vh',
  overflow: 'hidden',
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },

  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 824px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
  '& .wrapper_mobile': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  '& .form': {
    display: 'grid',
    rowGap: theme.spacing(1.25)
  },
  '& .footer': {
    display: 'flex',
    columnGap: theme.spacing(1.25),
    width: '100%',
    justifyContent: 'space-between',
    background: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0, 0, 1.5, 1.5)
  },
  '& .mobile': {
    borderRadius: 0,
    flexDirection: 'column',
    padding: '16px 24px 29px 24px',
    position: 'absolute',
    bottom: 0
  },
  '& .link': {
    color: theme.palette.text.primary
  }
}))
