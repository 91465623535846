//Library
import { memo } from 'react'
import date from 'date-and-time'
import { Link } from 'react-router-dom'
import __ from 'languages'

//Helpers
import helpers from 'helpers'
//Image
import avatarDefault from 'media/images/avatar_default.png'

//Scss
import './media/courses.income.table.scss'
import { Box, Table, Typography, styled } from '@mui/material'
import { EnumRole } from 'constant/index'
import Capacity from 'components/Capacity'

const CoursesIncomeTable = ({ data, index }) => {
  return (
    <CoursesIncomeTableContainer>
      <div id="coursesIncomeTable" className="coursesIncomeTable__container">
        <Box sx={{backgroundColor: theme => theme.palette.primary.main}} className={`coursesIncomeTable__header ${Number(index) && 'fromClient'}`}>
          <Typography className="coursesIncomeTable__item">{__("course_income_table_course")}</Typography>
          <Typography className="coursesIncomeTable__item">{__("course_income_table_buyer")}</Typography>
          <Typography className="coursesIncomeTable__item">{__("course_income_table_affiliate")}</Typography>
          <Typography className="coursesIncomeTable__item">{__("course_income_table_price")}</Typography>
          <Capacity
            current_user_can={[EnumRole.Boss, EnumRole.Admin, EnumRole.Super_Admin]}
            onFail={() => (
              <Typography className="coursesIncomeTable__item">{__("course_income_table_commission")}</Typography>

            )}
          >
            <Typography className="coursesIncomeTable__item">{__("course_income_table_commission_for_admin")}</Typography>

          </Capacity>
          <Typography className="coursesIncomeTable__item">{__("course_income_table_revenue")}</Typography>
        </Box>

        <Box sx={{
          display: 'grid',
          rowGap: 2
        }}>
          {data.map((item: any, idx: number) => (
            <Box
              key={idx}
              className={`coursesIncomeTable__list--wrapper ${
                idx % 2 ? 'evenNumber' : 'oddNumber'
              } ${Number(index) && 'fromClientWrapper'}`}
            >
              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">{__('course_title')}</span>
                <div className="d-flex flex-column justify-content-center">
                  <span className="coursesIncomeTable__list--name">{item?.ref_name}</span>
                  {item?.successfully_on && (
                    <span className="coursesIncomeTable__list--subTitle">
                      {__('course_income_table_order_date')}
                      {item?.successfully_on
                        ? date.format(new Date(item?.successfully_on), 'DD/MM/YYYY')
                        : '-'}
                    </span>
                  )}
                </div>
              </div>

              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">
                  {__('course_income_text_buyer')}
                </span>
                <div className="coursesIncomeTable__list--buyerWrapper">
                  {item?.user_id?._id ? (
                    <>
                      <Link to={`/user/detail/${item?.user_id?._id}`}>
                        <div className="coursesIncomeTable__list--image">
                          <img
                            src={
                              item?.user_id?.user_avatar_thumbnail ||
                              item?.user_id?.user_avatar ||
                              avatarDefault
                            }
                            onError={e => {
                              e.currentTarget.src = avatarDefault
                            }}
                            alt="image"
                            loading="lazy"
                          />
                        </div>
                      </Link>
                      <div className="coursesIncomeTable__link overflow-hidden">
                        <Link to={`/user/detail/${item?.user_id?._id}`}>
                          <span className="coursesIncomeTable__list--buyer">
                            {item?.user_id?.display_name}
                          </span>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <span className="coursesIncomeTable__list--buyer">-</span>
                  )}
                </div>
              </div>

              {item?.successfully_on && (
                <div className="coursesIncomeTable__list--buyTimeMobile">
                  <span className="coursesIncomeTable__list--headerMobile">
                    {__('course_income_text_buy_time')}
                  </span>
                  <span className="coursesIncomeTable__list--buyTime">
                    {item?.successfully_on
                      ? date.format(new Date(item?.successfully_on), 'DD/MM/YYYY')
                      : '-'}
                  </span>
                </div>
              )}

              {item?.from_user?._id && (
                <div className="coursesIncomeTable__list--item affiliateBlock">
                  <span className="coursesIncomeTable__list--headerMobile">
                    {__('course_income_text_affiliate')}
                  </span>
                  <div className="coursesIncomeTable__list--affiliateWrapper">
                    <Link to={`/user/detail/${item?.from_user?._id}`}>
                      <div className="coursesIncomeTable__list--image">
                        <img
                          src={
                            item?.user_id?.user_avatar_thumbnail ||
                            item?.from_user?.user_avatar ||
                            avatarDefault
                          }
                          onError={e => {
                            e.currentTarget.src = avatarDefault
                          }}
                          alt="image"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                    <div className="coursesIncomeTable__link overflow-hidden">
                      <Link
                        className="text_decoration_none"
                        to={`/user/detail/${item?.from_user?._id}`}
                      >
                        <span className="coursesIncomeTable__list--contentName">
                          {item?.from_user?.display_name ?? '-'}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`coursesIncomeTable__list--item ${
                  !item?.from_user?._id && 'displayPosition'
                }`}
              >
                <span className="coursesIncomeTable__list--headerMobile">
                  {__('course_income_text_price')}
                </span>
                <Typography
                  variant={'subtitle2'}
                  color={'primary.main'}
                  fontWeight={900}
                  className={`coursesIncomeTable_list-price ${Number(index) && 'fromClientItem'}`}
                >
                  {helpers.convertToCommasFormat(item?.transaction_value || '0')}
                </Typography>
              </div>

              <div className="coursesIncomeTable__list--item coursesIncomeTable__list--itemMobile">
                <span className="coursesIncomeTable__list--headerMobile">
                  {__('course_income_text_commission')}
                </span>
                <span className="coursesIncomeTable__list--percent">
                  {item?.commission_value || '0'}%
                </span>
              </div>

              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">
                  {__('course_income_text_revenue')}
                </span>
                <div className="coursesIncomeTable__list--revenueMobile">
                  <Typography
                    variant={'body1'}
                    color={'primary.main'}
                    fontWeight={600}
                    className="coursesIncomeTable__list--percentMobile"
                  >{`(${
                    item?.commission_value || '0'
                  }%)`}
                  </Typography>
                  <Typography
                    variant={'subtitle2'}
                    color={'primary.main'}
                    fontWeight={900}
                    className={`coursesIncomeTable__list--revenue ${
                      Number(index) && 'fromClientItem'
                    }`}
                  >
                    {helpers.convertToCommasFormat(item?.revenue_value || '0')}
                  </Typography>
                </div>
              </div>
            </Box>
          ))}
        </Box>
      </div>
    </CoursesIncomeTableContainer>
  )
}

export default memo(CoursesIncomeTable)
const CoursesIncomeTableContainer = styled(Table)(({theme})=>({
  '& .coursesIncomeTable_list-price':{

  },
  '& .coursesIncomeTable__item' : {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.spacing(2),
  fontWeight: 600,
  color: theme.palette.background.paper,
    ':nth-child(1)':{
      justifyContent:'flex-start'
    },
    ':nth-child(2)':{
      justifyContent:'flex-start'
    },
    ':nth-child(3)':{
      justifyContent:'flex-start'
    }},
  '& .coursesIncomeTable__list--wrapper':{
    display: 'grid',
    height: 'auto',
    gridTemplateColumns: 'repeat(6, 1fr)',
    justifyContent: 'flex-start',
    columnsGap: theme.spacing(0.75),
    padding : theme.spacing(0.75, 2.5)
  },
  '& .evenNumber':{
    backgroundColor: theme.palette.primary.backgroundHover,
  },
  '& .oddNumber':{
    backgroundColor: theme.palette.background.paper
  },
  '& .evenNumber.fromClientWrapper': {
    backgroundColor: theme.palette.primary.backgroundHover,
  },
  ' @media screen and (max-width: 1200px)':{
    '& .coursesIncomeTable__list--wrapper':{
      display: 'grid',
      height: 'auto',
      gridTemplateColumns: '1fr',
      justifyContent: 'space-between',
      columnsGap: theme.spacing(0.75),
      padding : theme.spacing(1, 2.75),
      borderRadius: theme.spacing(0.5),
      border:` 1px solid rgba(0, 0, 0, 0.103)`
    },
  }
}))
