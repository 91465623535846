//Library
import React, { useEffect, useRef, useState } from 'react'
import __, { ___ } from 'languages/index'
import { Link, useNavigate } from 'react-router-dom'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  keyframes,
  styled
} from '@mui/material'

//Component
import SocialNetworkComponent from './component/social.login'
import ProgressBarPassword from 'components/ProgressBarPassword'
import _Helmet from 'components/helmet'
import TextFieldMUI from 'components/TextFieldMUI'

//Helper
import helpers from 'helpers'

//Images
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'

//Store
import {
  checkJoinChannel,
  clearError,
  getCurrentUserData,
  getDefaultChannel,
  getUserPermission
} from '../store/user.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'
import { registerAccount } from 'store/auth.store.reducer'

//Icons
import {
  ArrowLeftIcon,
  EmailIcon,
  EyeIcon,
  EyeOffIcon,
  MobileIcon,
  SafetyClockIcon,
  UserIcon
} from 'icons'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'
import { ROUTER } from 'constant/router'
import { formatString } from 'entities/support/utils'

export default function RegisterPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const passwordRef = useRef(null)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleShowTextError = (text: string) => {
    let errorMessage = ''
    switch (text) {
      case 'account_existed':
        errorMessage = `${__('error_account_existed')}`
        break
      default:
        errorMessage = text
        break
    }
    return errorMessage
  }

  const handleCheckJoinChannel = async () => {
    return await dispatch(checkJoinChannel())
      .unwrap()
      .then(res => {
        if (res?.data?.is_join) {
          dispatch(getCurrentUserData())
          navigate('/', { replace: true })
        } else {
          navigate(`/channel/join`)
        }
      })
      .catch(error => {
        console.log('checkJoinChannel Error >>>', error)
      })
  }

  const { fields, submit, dirty, submitting } = useForm({
    fields: {
      referrer_code: useField<string>({
        value: '',
        validates: []
      }),
      user_phone: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_phone_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_phone_not_empty')}`
            }
            if (!helpers.isPhoneNumber(inputVal)) {
              return `${__('validate_phone')}`
            }
          }
        ]
      }),
      user_email: useField<string>({
        value: '',
        validates: [
          notEmpty(formatString(__('validate_not_empty'), 'email')),
          inputVal => {
            if (!inputVal) {
              return formatString(__('validate_not_empty'), 'email')
            }
            if (!helpers.isEmail(inputVal)) {
              return formatString(__('validate_pattern'), 'Email')
            }
          }
        ]
      }),
      fullName: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_username_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_username_not_empty')}`
            }
          }
        ]
      }),
      password: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_password_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_password_not_empty')}`
            }
            if (helpers.getPasswordStrength(inputVal) < 1) {
              return `${__('register_password_invalid')}`
            }
          }
        ]
      }),
      confirm_password: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_re-enter_password')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_re-enter_password')}`
            }
            if (inputVal !== passwordRef?.current) {
              return `${__('register_password_not_match')}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          referrer_code: values.referrer_code,
          user_email: values.user_email.trim(),
          user_phone: values.user_phone.trim(),
          user_password: values.password.trim(),
          device_uuid: '',
          device_type: 'website',
          domain: window.location.origin,
          full_name: values.fullName.trim()
        }
        setIsLoading(true)
        let _registerRes: any = await dispatch(registerAccount(formData))
        if (typeof _registerRes.error !== 'undefined') {
          setError(_registerRes.error.message || `${__('login_error_unknown')}`)
          setIsLoading(false)
          return { status: 'success' }
        }
        const response = _registerRes?.payload?.data || ''
        const authCode = _registerRes?.payload?.headers['x-authorization'] || ''

        document.cookie = `session=${authCode};path=/;SameSite=Lax`
        // SSO
        localStorage.setItem('session', authCode)
        setError('')
        dispatch(clearError())
        setIsLoading(false)
        await dispatch(getDefaultChannel())
        await dispatch(getUserPermission())
        if (!response?.register_mode) {
          handleCheckJoinChannel()
        } else {
          if (response?.register_mode === 'sms') {
            if (!response?.user_verified_phone) {
              navigate('/validate-code#phone')
              localStorage.setItem('user_input_verify', JSON.stringify(response?.user_phone || ''))
            } else {
              handleCheckJoinChannel()
            }
          }
          if (response?.register_mode === 'email') {
            if (!response?.user_verified_email) {
              navigate('/validate-code#email')
              localStorage.setItem('user_input_verify', JSON.stringify(response?.user_email || ''))
            } else {
              handleCheckJoinChannel()
            }
          }
        }
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
    try {
      const invitationData = localStorage.getItem('referrer_code')
      if (!invitationData) return
      fields.referrer_code.onChange(invitationData)
    } catch (error) {
      fields.referrer_code.onChange('')
    }
    return () => {
      localStorage.removeItem('referrer_code')
    }
  }, [])

  return (
    <>
      <_Helmet title="register" />
      <RegisterContainer id="registerPage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />

        <Box id={'box_form'} className="wrapper">
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: 'auto',
              width: '100%'
            }}
          >
            <Box>
              {error && <Alert severity="error">{handleShowTextError(error)}</Alert>}
              <Box
                display={'grid'}
                rowGap={theme => theme.spacing(3)}
                padding={theme => theme.spacing(3)}
              >
                <Box justifyContent={'flex-start'}>
                  <IconButton sx={{ p: 0 }}>
                    <Link to={ROUTER.AUTH.LOGIN}>
                      <ArrowLeftIcon sx={{ color: theme => theme.palette.text.primary }} />
                    </Link>
                  </IconButton>
                </Box>
                <Typography
                  variant="h5"
                  textTransform={'none'}
                  fontWeight={700}
                  textAlign={'start'}
                >
                  {__('register')}
                </Typography>
                <form onSubmit={submit} className="form">
                  <TextFieldMUI
                    placeholder={__('user_name')}
                    {...fields.fullName}
                    autoFocus
                    iconStart={<UserIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    id="full_name"
                  />
                  <TextFieldMUI
                    placeholder={__('Email')}
                    {...fields.user_email}
                    iconStart={<EmailIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    id="user_email"
                  />
                  <TextFieldMUI
                    placeholder={__('phone_number')}
                    {...fields.user_phone}
                    maxLength={10}
                    onChange={(value: string) =>
                      fields.user_phone.onChange(`${value?.replace(/\D+/g, '')}`)
                    }
                    iconStart={<MobileIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    id="user_phone"
                  />
                  <Box>
                    <TextFieldMUI
                      type={isShowPassword ? 'text' : 'password'}
                      placeholder={__('register_form_label_password')}
                      {...fields.password}
                      id="password"
                      onChange={(value: string) => {
                        fields.password.onChange(value)
                        passwordRef.current = value
                      }}
                      iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      iconEnd={
                        <IconButton
                          type="button"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          sx={{ p: 0 }}
                        >
                          {isShowPassword ? (
                            <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          ) : (
                            <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          )}
                        </IconButton>
                      }
                      error={fields.password.value ? '' : fields.password.error}
                    />
                    {fields.password.value && (
                      <ProgressBarPassword
                        strength_password={helpers.getPasswordStrength(fields.password.value)}
                      />
                    )}
                  </Box>
                  <TextFieldMUI
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    placeholder={__('register_form_label_re-enter_password')}
                    {...fields.confirm_password}
                    id="confirm_password"
                    iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    iconEnd={
                      <IconButton
                        type="button"
                        onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                        sx={{ p: 0 }}
                      >
                        {isShowConfirmPassword ? (
                          <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} />
                        ) : (
                          <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />
                        )}
                      </IconButton>
                    }
                  />
                  <Button
                    disabled={!dirty || submitting || isLoading}
                    type="submit"
                    sx={{ p: theme => theme.spacing(2.1875, 2) }}
                  >
                    <Typography textTransform={'none'}>
                      {isLoading ? __('btn_processing') : __('register_btn_register')}
                    </Typography>
                  </Button>
                </form>
                <SocialNetworkComponent />
                <Typography sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {___('By log in, you agree to our {terms} and {policies}', {
                    terms: (
                      <>
                        &nbsp;
                        <Link to="/tos" className="link">
                          <Typography color={'text.primary'} fontWeight={600}>
                            {` ${__('terms_of_use')} `}
                          </Typography>
                        </Link>
                        &nbsp;
                      </>
                    ),
                    policies: (
                      <>
                        &nbsp;
                        <Link to="/tos" className="link">
                          <Typography color={'text.primary'} fontWeight={600}>
                            {` ${__('policies')} `}
                          </Typography>
                        </Link>
                      </>
                    )
                  })}
                </Typography>
              </Box>
            </Box>
            <Paper className="footer">
              <Box display={'flex'} alignItems={'center'}>
                <Typography>{__('register_question')}</Typography>
                <Button variant="text" sx={{ p: 0 }}>
                  <Link to={ROUTER.AUTH.LOGIN} style={{ textDecoration: 'none' }}>
                    <Typography color="primary.main" textTransform={'none'} fontWeight={600}>
                      &nbsp;{__('login')}
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Paper>
          </Paper>
        </Box>
      </RegisterContainer>
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`

const RegisterContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& #box_form': {
    position: 'absolute',
    top: 0
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 824px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
  '& .footer': {
    display: 'flex',
    columnGap: theme.spacing(1.25),
    width: '100%',
    justifyContent: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0, 0, 1.5, 1.5)
  },
  '& .form': {
    display: 'grid',
    rowGap: theme.spacing(1.25)
  },
  '& .link': {
    color: theme.palette.text.primary
  }
}))
