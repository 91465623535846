import React from 'react'
import { IconButton, Stack } from '@mui/material'
import { CheckIcon, EditIcon, TrashIcon } from 'icons'

type Props = {
  onClickDeleteBtn?: () => void
  onClickEditBtn?: () => void
  editing?: boolean
  onConfirm?: () => void
}
const RowActions = ({ editing, onConfirm, onClickDeleteBtn, onClickEditBtn }: Props) => {

  return (
    <Stack direction="row" gap={1} justifyContent={'flex-end'}>
      {onClickDeleteBtn && (
        <IconButton
          onClick={onClickDeleteBtn}
          sx={{
            border: theme => `1px solid ${theme.palette.divider}`,
            borderRadius: theme => theme.spacing(1.5),
            color: theme => theme.palette.text.primary
          }}
        >
          <TrashIcon fontSize={'small'} />
        </IconButton>
      )}
      {onClickEditBtn && (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            if (editing) {
              return onConfirm()
            }
            onClickEditBtn()
          }}
          sx={{
            borderRadius: theme => theme.spacing(1.5),
            backgroundColor: theme => theme.palette.primary.main,
            color: theme => theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme => theme.palette.primary.light
            }
          }}
        >
          {Boolean(!editing && onClickEditBtn) ? <EditIcon fontSize={'small'} /> : <CheckIcon fontSize={'small'} />}
        </IconButton>
      )}
    </Stack>
  )
}

export default RowActions
